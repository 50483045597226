const apiHost = process.env.GATSBY_MEASUR_API_HOST
if (!apiHost) {
  throw new Error('API URL not set')
}

const publicConfig = {
  apiHost,
  bannerText: process.env.GATSBY_BANNER_TEXT,
  gtmId: process.env.GTM_ID,
  maxCheckoutSamples: Math.max(
    process.env.MAX_CHECKOUT_SAMPLES
      ? Number(process.env.MAX_CHECKOUT_SAMPLES)
      : 1,
    50,
  ),
  oidc: {
    audience: process.env.GATSBY_OIDC_AUDIENCE,
    authorizeTimeoutInSeconds: process.env
      .GATSBY_OIDC_AUTHORIZE_TIMEOUT_IN_SECONDS
      ? Number(process.env.GATSBY_OIDC_AUTHORIZE_TIMEOUT_IN_SECONDS)
      : undefined,
    clientId: process.env.GATSBY_OIDC_CLIENTID,
    domain: process.env.GATSBY_OIDC_DOMAIN,
    issuer: process.env.GATSBY_OIDC_ISSUER,
  },
}

export default publicConfig
