export type CountryCode =
  | 'AC'
  | 'AD'
  | 'AE'
  | 'AF'
  | 'AG'
  | 'AI'
  | 'AL'
  | 'AM'
  | 'AO'
  | 'AQ'
  | 'AR'
  | 'AS'
  | 'AT'
  | 'AU'
  | 'AW'
  | 'AX'
  | 'AZ'
  | 'BA'
  | 'BB'
  | 'BD'
  | 'BE'
  | 'BF'
  | 'BG'
  | 'BH'
  | 'BI'
  | 'BJ'
  | 'BL'
  | 'BM'
  | 'BN'
  | 'BO'
  | 'BQ'
  | 'BR'
  | 'BS'
  | 'BT'
  | 'BV'
  | 'BW'
  | 'BY'
  | 'BZ'
  | 'CA'
  | 'CC'
  | 'CD'
  | 'CF'
  | 'CG'
  | 'CH'
  | 'CI'
  | 'CK'
  | 'CL'
  | 'CM'
  | 'CN'
  | 'CO'
  | 'CP'
  | 'CR'
  | 'CU'
  | 'CV'
  | 'CW'
  | 'CX'
  | 'CY'
  | 'CZ'
  | 'DE'
  | 'DG'
  | 'DJ'
  | 'DK'
  | 'DM'
  | 'DO'
  | 'DZ'
  | 'EA'
  | 'EC'
  | 'EE'
  | 'EG'
  | 'EH'
  | 'ER'
  | 'ES'
  | 'ET'
  | 'EU'
  | 'EZ'
  | 'FI'
  | 'FJ'
  | 'FK'
  | 'FM'
  | 'FO'
  | 'FR'
  | 'GA'
  | 'GB'
  | 'GD'
  | 'GE'
  | 'GF'
  | 'GG'
  | 'GH'
  | 'GI'
  | 'GL'
  | 'GM'
  | 'GN'
  | 'GP'
  | 'GQ'
  | 'GR'
  | 'GS'
  | 'GT'
  | 'GU'
  | 'GW'
  | 'GY'
  | 'HK'
  | 'HM'
  | 'HN'
  | 'HR'
  | 'HT'
  | 'HU'
  | 'IC'
  | 'ID'
  | 'IE'
  | 'IL'
  | 'IM'
  | 'IN'
  | 'IO'
  | 'IQ'
  | 'IR'
  | 'IS'
  | 'IT'
  | 'JE'
  | 'JM'
  | 'JO'
  | 'JP'
  | 'KE'
  | 'KG'
  | 'KH'
  | 'KI'
  | 'KM'
  | 'KN'
  | 'KP'
  | 'KR'
  | 'KW'
  | 'KY'
  | 'KZ'
  | 'LA'
  | 'LB'
  | 'LC'
  | 'LI'
  | 'LK'
  | 'LR'
  | 'LS'
  | 'LT'
  | 'LU'
  | 'LV'
  | 'LY'
  | 'MA'
  | 'MC'
  | 'MD'
  | 'ME'
  | 'MF'
  | 'MG'
  | 'MH'
  | 'MK'
  | 'ML'
  | 'MM'
  | 'MN'
  | 'MO'
  | 'MP'
  | 'MQ'
  | 'MR'
  | 'MS'
  | 'MT'
  | 'MU'
  | 'MV'
  | 'MW'
  | 'MX'
  | 'MY'
  | 'MZ'
  | 'NA'
  | 'NC'
  | 'NE'
  | 'NF'
  | 'NG'
  | 'NI'
  | 'NL'
  | 'NO'
  | 'NP'
  | 'NR'
  | 'NU'
  | 'NZ'
  | 'OM'
  | 'PA'
  | 'PE'
  | 'PF'
  | 'PG'
  | 'PH'
  | 'PK'
  | 'PL'
  | 'PM'
  | 'PN'
  | 'PR'
  | 'PS'
  | 'PT'
  | 'PW'
  | 'PY'
  | 'QA'
  | 'QO'
  | 'RE'
  | 'RO'
  | 'RS'
  | 'RU'
  | 'RW'
  | 'SA'
  | 'SB'
  | 'SC'
  | 'SD'
  | 'SE'
  | 'SG'
  | 'SH'
  | 'SI'
  | 'SJ'
  | 'SK'
  | 'SL'
  | 'SM'
  | 'SN'
  | 'SO'
  | 'SR'
  | 'SS'
  | 'ST'
  | 'SV'
  | 'SX'
  | 'SY'
  | 'SZ'
  | 'TA'
  | 'TC'
  | 'TD'
  | 'TF'
  | 'TG'
  | 'TH'
  | 'TJ'
  | 'TK'
  | 'TL'
  | 'TM'
  | 'TN'
  | 'TO'
  | 'TR'
  | 'TT'
  | 'TV'
  | 'TW'
  | 'TZ'
  | 'UA'
  | 'UG'
  | 'UM'
  | 'US'
  | 'UY'
  | 'UZ'
  | 'VA'
  | 'VC'
  | 'VE'
  | 'VG'
  | 'VI'
  | 'VN'
  | 'VU'
  | 'WF'
  | 'WS'
  | 'XK'
  | 'YE'
  | 'YT'
  | 'ZA'
  | 'ZM'
  | 'ZW'

// https://github.com/unicode-org/cldr-json/blob/f27f55f1dd487af7cf4260f56296ee1c7649b7fc/cldr-json/cldr-localenames-full/main/en-001/territories.json
export const territoriesEn: { [c in CountryCode]: string } = {
  AC: 'Ascension Island',
  AD: 'Andorra',
  AE: 'United Arab Emirates',
  AF: 'Afghanistan',
  AG: 'Antigua & Barbuda',
  AI: 'Anguilla',
  AL: 'Albania',
  AM: 'Armenia',
  AO: 'Angola',
  AQ: 'Antarctica',
  AR: 'Argentina',
  AS: 'American Samoa',
  AT: 'Austria',
  AU: 'Australia',
  AW: 'Aruba',
  AX: 'Åland Islands',
  AZ: 'Azerbaijan',
  BA: 'Bosnia & Herzegovina',
  BB: 'Barbados',
  BD: 'Bangladesh',
  BE: 'Belgium',
  BF: 'Burkina Faso',
  BG: 'Bulgaria',
  BH: 'Bahrain',
  BI: 'Burundi',
  BJ: 'Benin',
  BL: 'St Barthélemy',
  BM: 'Bermuda',
  BN: 'Brunei',
  BO: 'Bolivia',
  BQ: 'Caribbean Netherlands',
  BR: 'Brazil',
  BS: 'Bahamas',
  BT: 'Bhutan',
  BV: 'Bouvet Island',
  BW: 'Botswana',
  BY: 'Belarus',
  BZ: 'Belize',
  CA: 'Canada',
  CC: 'Cocos (Keeling) Islands',
  CD: 'Congo - Kinshasa',
  CF: 'Central African Republic',
  CG: 'Congo - Brazzaville',
  CH: 'Switzerland',
  CI: 'Côte d’Ivoire (Ivory Coast)',
  CK: 'Cook Islands',
  CL: 'Chile',
  CM: 'Cameroon',
  CN: 'China',
  CO: 'Colombia',
  CP: 'Clipperton Island',
  CR: 'Costa Rica',
  CU: 'Cuba',
  CV: 'Cape Verde',
  CW: 'Curaçao',
  CX: 'Christmas Island',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DE: 'Germany',
  DG: 'Diego Garcia',
  DJ: 'Djibouti',
  DK: 'Denmark',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  DZ: 'Algeria',
  EA: 'Ceuta & Melilla',
  EC: 'Ecuador',
  EE: 'Estonia',
  EG: 'Egypt',
  EH: 'Western Sahara',
  ER: 'Eritrea',
  ES: 'Spain',
  ET: 'Ethiopia',
  EU: 'European Union',
  EZ: 'Eurozone',
  FI: 'Finland',
  FJ: 'Fiji',
  FK: 'Falkland Islands',
  FM: 'Micronesia',
  FO: 'Faroe Islands',
  FR: 'France',
  GA: 'Gabon',
  GB: 'United Kingdom',
  GD: 'Grenada',
  GE: 'Georgia',
  GF: 'French Guiana',
  GG: 'Guernsey',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GL: 'Greenland',
  GM: 'Gambia',
  GN: 'Guinea',
  GP: 'Guadeloupe',
  GQ: 'Equatorial Guinea',
  GR: 'Greece',
  GS: 'South Georgia & South Sandwich Islands',
  GT: 'Guatemala',
  GU: 'Guam',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HK: 'Hong Kong',
  HM: 'Heard & McDonald Islands',
  HN: 'Honduras',
  HR: 'Croatia',
  HT: 'Haiti',
  HU: 'Hungary',
  IC: 'Canary Islands',
  ID: 'Indonesia',
  IE: 'Ireland',
  IL: 'Israel',
  IM: 'Isle of Man',
  IN: 'India',
  IO: 'British Indian Ocean Territory',
  IQ: 'Iraq',
  IR: 'Iran',
  IS: 'Iceland',
  IT: 'Italy',
  JE: 'Jersey',
  JM: 'Jamaica',
  JO: 'Jordan',
  JP: 'Japan',
  KE: 'Kenya',
  KG: 'Kyrgyzstan',
  KH: 'Cambodia',
  KI: 'Kiribati',
  KM: 'Comoros',
  KN: 'St Kitts & Nevis',
  KP: 'North Korea',
  KR: 'South Korea',
  KW: 'Kuwait',
  KY: 'Cayman Islands',
  KZ: 'Kazakhstan',
  LA: 'Laos',
  LB: 'Lebanon',
  LC: 'St Lucia',
  LI: 'Liechtenstein',
  LK: 'Sri Lanka',
  LR: 'Liberia',
  LS: 'Lesotho',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  LV: 'Latvia',
  LY: 'Libya',
  MA: 'Morocco',
  MC: 'Monaco',
  MD: 'Moldova',
  ME: 'Montenegro',
  MF: 'St Martin',
  MG: 'Madagascar',
  MH: 'Marshall Islands',
  MK: 'North Macedonia',
  ML: 'Mali',
  MM: 'Myanmar',
  MN: 'Mongolia',
  MO: 'Macao',
  MP: 'Northern Mariana Islands',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MS: 'Montserrat',
  MT: 'Malta',
  MU: 'Mauritius',
  MV: 'Maldives',
  MW: 'Malawi',
  MX: 'Mexico',
  MY: 'Malaysia',
  MZ: 'Mozambique',
  NA: 'Namibia',
  NC: 'New Caledonia',
  NE: 'Niger',
  NF: 'Norfolk Island',
  NG: 'Nigeria',
  NI: 'Nicaragua',
  NL: 'Netherlands',
  NO: 'Norway',
  NP: 'Nepal',
  NR: 'Nauru',
  NU: 'Niue',
  NZ: 'New Zealand',
  OM: 'Oman',
  PA: 'Panama',
  PE: 'Peru',
  PF: 'French Polynesia',
  PG: 'Papua New Guinea',
  PH: 'Philippines',
  PK: 'Pakistan',
  PL: 'Poland',
  PM: 'St Pierre & Miquelon',
  PN: 'Pitcairn Islands',
  PR: 'Puerto Rico',
  PS: 'Palestine',
  PT: 'Portugal',
  PW: 'Palau',
  PY: 'Paraguay',
  QA: 'Qatar',
  QO: 'Outlying Oceania',
  RE: 'Réunion',
  RO: 'Romania',
  RS: 'Serbia',
  RU: 'Russia',
  RW: 'Rwanda',
  SA: 'Saudi Arabia',
  SB: 'Solomon Islands',
  SC: 'Seychelles',
  SD: 'Sudan',
  SE: 'Sweden',
  SG: 'Singapore',
  SH: 'St Helena',
  SI: 'Slovenia',
  SJ: 'Svalbard & Jan Mayen',
  SK: 'Slovakia',
  SL: 'Sierra Leone',
  SM: 'San Marino',
  SN: 'Senegal',
  SO: 'Somalia',
  SR: 'Suriname',
  SS: 'South Sudan',
  ST: 'São Tomé & Príncipe',
  SV: 'El Salvador',
  SX: 'Sint Maarten',
  SY: 'Syria',
  SZ: 'Eswatini / Swaziland',
  TA: 'Tristan da Cunha',
  TC: 'Turks & Caicos Islands',
  TD: 'Chad',
  TF: 'French Southern Territories',
  TG: 'Togo',
  TH: 'Thailand',
  TJ: 'Tajikistan',
  TK: 'Tokelau',
  TL: 'Timor-Leste',
  TM: 'Turkmenistan',
  TN: 'Tunisia',
  TO: 'Tonga',
  TR: 'Turkey / Türkiye',
  TT: 'Trinidad & Tobago',
  TV: 'Tuvalu',
  TW: 'Taiwan',
  TZ: 'Tanzania',
  UA: 'Ukraine',
  UG: 'Uganda',
  UM: 'US Outlying Islands',
  US: 'United States',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VA: 'Vatican City',
  VC: 'St Vincent & the Grenadines',
  VE: 'Venezuela',
  VG: 'British Virgin Islands',
  VI: 'US Virgin Islands',
  VN: 'Vietnam',
  VU: 'Vanuatu',
  WF: 'Wallis & Futuna',
  WS: 'Samoa',
  XK: 'Kosovo',
  YE: 'Yemen',
  YT: 'Mayotte',
  ZA: 'South Africa',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
}

// https://github.com/unicode-org/cldr-json/blob/f27f55f1dd487af7cf4260f56296ee1c7649b7fc/cldr-json/cldr-localenames-full/main/fi/territories.json
export const territoriesFi: { [c in CountryCode]: string } = {
  AC: 'Ascension-saari',
  AD: 'Andorra',
  AE: 'Arabiemiirikunnat',
  AF: 'Afganistan',
  AG: 'Antigua ja Barbuda',
  AI: 'Anguilla',
  AL: 'Albania',
  AM: 'Armenia',
  AO: 'Angola',
  AQ: 'Antarktis',
  AR: 'Argentiina',
  AS: 'Amerikan Samoa',
  AT: 'Itävalta',
  AU: 'Australia',
  AW: 'Aruba',
  AX: 'Ahvenanmaa',
  AZ: 'Azerbaidžan',
  BA: 'Bosnia ja Hertsegovina',
  BB: 'Barbados',
  BD: 'Bangladesh',
  BE: 'Belgia',
  BF: 'Burkina Faso',
  BG: 'Bulgaria',
  BH: 'Bahrain',
  BI: 'Burundi',
  BJ: 'Benin',
  BL: 'Saint-Barthélemy',
  BM: 'Bermuda',
  BN: 'Brunei',
  BO: 'Bolivia',
  BQ: 'Karibian Alankomaat',
  BR: 'Brasilia',
  BS: 'Bahama',
  BT: 'Bhutan',
  BV: 'Bouvet’nsaari',
  BW: 'Botswana',
  BY: 'Valko-Venäjä',
  BZ: 'Belize',
  CA: 'Kanada',
  CC: 'Kookossaaret (Keelingsaaret)',
  CD: 'Kongon demokraattinen tasavalta',
  CF: 'Keski-Afrikan tasavalta',
  CG: 'Kongo-Brazzaville',
  CH: 'Sveitsi',
  CI: 'Norsunluurannikko',
  CK: 'Cookinsaaret',
  CL: 'Chile',
  CM: 'Kamerun',
  CN: 'Kiina',
  CO: 'Kolumbia',
  CP: 'Clippertoninsaari',
  CR: 'Costa Rica',
  CU: 'Kuuba',
  CV: 'Kap Verde',
  CW: 'Curaçao',
  CX: 'Joulusaari',
  CY: 'Kypros',
  CZ: 'Tšekki',
  DE: 'Saksa',
  DG: 'Diego Garcia',
  DJ: 'Djibouti',
  DK: 'Tanska',
  DM: 'Dominica',
  DO: 'Dominikaaninen tasavalta',
  DZ: 'Algeria',
  EA: 'Ceuta ja Melilla',
  EC: 'Ecuador',
  EE: 'Viro',
  EG: 'Egypti',
  EH: 'Länsi-Sahara',
  ER: 'Eritrea',
  ES: 'Espanja',
  ET: 'Etiopia',
  EU: 'Euroopan unioni',
  EZ: 'euroalue',
  FI: 'Suomi',
  FJ: 'Fidži',
  FK: 'Falklandinsaaret',
  FM: 'Mikronesia',
  FO: 'Färsaaret',
  FR: 'Ranska',
  GA: 'Gabon',
  GB: 'Iso-Britannia',
  GD: 'Grenada',
  GE: 'Georgia',
  GF: 'Ranskan Guayana',
  GG: 'Guernsey',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GL: 'Grönlanti',
  GM: 'Gambia',
  GN: 'Guinea',
  GP: 'Guadeloupe',
  GQ: 'Päiväntasaajan Guinea',
  GR: 'Kreikka',
  GS: 'Etelä-Georgia ja Eteläiset Sandwichinsaaret',
  GT: 'Guatemala',
  GU: 'Guam',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HK: 'Hongkong',
  HM: 'Heard ja McDonaldinsaaret',
  HN: 'Honduras',
  HR: 'Kroatia',
  HT: 'Haiti',
  HU: 'Unkari',
  IC: 'Kanariansaaret',
  ID: 'Indonesia',
  IE: 'Irlanti',
  IL: 'Israel',
  IM: 'Mansaari',
  IN: 'Intia',
  IO: 'Brittiläinen Intian valtameren alue',
  IQ: 'Irak',
  IR: 'Iran',
  IS: 'Islanti',
  IT: 'Italia',
  JE: 'Jersey',
  JM: 'Jamaika',
  JO: 'Jordania',
  JP: 'Japani',
  KE: 'Kenia',
  KG: 'Kirgisia',
  KH: 'Kambodža',
  KI: 'Kiribati',
  KM: 'Komorit',
  KN: 'Saint Kitts ja Nevis',
  KP: 'Pohjois-Korea',
  KR: 'Etelä-Korea',
  KW: 'Kuwait',
  KY: 'Caymansaaret',
  KZ: 'Kazakstan',
  LA: 'Laos',
  LB: 'Libanon',
  LC: 'Saint Lucia',
  LI: 'Liechtenstein',
  LK: 'Sri Lanka',
  LR: 'Liberia',
  LS: 'Lesotho',
  LT: 'Liettua',
  LU: 'Luxemburg',
  LV: 'Latvia',
  LY: 'Libya',
  MA: 'Marokko',
  MC: 'Monaco',
  MD: 'Moldova',
  ME: 'Montenegro',
  MF: 'Saint-Martin',
  MG: 'Madagaskar',
  MH: 'Marshallinsaaret',
  MK: 'Pohjois-Makedonia',
  ML: 'Mali',
  MM: 'Myanmar (Burma)',
  MN: 'Mongolia',
  MO: 'Macao',
  MP: 'Pohjois-Mariaanit',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MS: 'Montserrat',
  MT: 'Malta',
  MU: 'Mauritius',
  MV: 'Malediivit',
  MW: 'Malawi',
  MX: 'Meksiko',
  MY: 'Malesia',
  MZ: 'Mosambik',
  NA: 'Namibia',
  NC: 'Uusi-Kaledonia',
  NE: 'Niger',
  NF: 'Norfolkinsaari',
  NG: 'Nigeria',
  NI: 'Nicaragua',
  NL: 'Alankomaat',
  NO: 'Norja',
  NP: 'Nepal',
  NR: 'Nauru',
  NU: 'Niue',
  NZ: 'Uusi-Seelanti',
  OM: 'Oman',
  PA: 'Panama',
  PE: 'Peru',
  PF: 'Ranskan Polynesia',
  PG: 'Papua-Uusi-Guinea',
  PH: 'Filippiinit',
  PK: 'Pakistan',
  PL: 'Puola',
  PM: 'Saint-Pierre ja Miquelon',
  PN: 'Pitcairn',
  PR: 'Puerto Rico',
  PS: 'Palestiina',
  PT: 'Portugali',
  PW: 'Palau',
  PY: 'Paraguay',
  QA: 'Qatar',
  QO: 'Oseanian erillissaaret',
  RE: 'Réunion',
  RO: 'Romania',
  RS: 'Serbia',
  RU: 'Venäjä',
  RW: 'Ruanda',
  SA: 'Saudi-Arabia',
  SB: 'Salomonsaaret',
  SC: 'Seychellit',
  SD: 'Sudan',
  SE: 'Ruotsi',
  SG: 'Singapore',
  SH: 'Saint Helena',
  SI: 'Slovenia',
  SJ: 'Svalbard ja Jan Mayen',
  SK: 'Slovakia',
  SL: 'Sierra Leone',
  SM: 'San Marino',
  SN: 'Senegal',
  SO: 'Somalia',
  SR: 'Suriname',
  SS: 'Etelä-Sudan',
  ST: 'São Tomé ja Príncipe',
  SV: 'El Salvador',
  SX: 'Sint Maarten',
  SY: 'Syyria',
  SZ: 'Eswatini / Swazimaa',
  TA: 'Tristan da Cunha',
  TC: 'Turks- ja Caicossaaret',
  TD: 'Tšad',
  TF: 'Ranskan eteläiset ja antarktiset alueet',
  TG: 'Togo',
  TH: 'Thaimaa',
  TJ: 'Tadžikistan',
  TK: 'Tokelau',
  TL: 'Itä-Timor',
  TM: 'Turkmenistan',
  TN: 'Tunisia',
  TO: 'Tonga',
  TR: 'Turkki',
  TT: 'Trinidad ja Tobago',
  TV: 'Tuvalu',
  TW: 'Taiwan',
  TZ: 'Tansania',
  UA: 'Ukraina',
  UG: 'Uganda',
  UM: 'Yhdysvaltain erillissaaret',
  US: 'Yhdysvallat',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VA: 'Vatikaani',
  VC: 'Saint Vincent ja Grenadiinit',
  VE: 'Venezuela',
  VG: 'Brittiläiset Neitsytsaaret',
  VI: 'Yhdysvaltain Neitsytsaaret',
  VN: 'Vietnam',
  VU: 'Vanuatu',
  WF: 'Wallis ja Futuna',
  WS: 'Samoa',
  XK: 'Kosovo',
  YE: 'Jemen',
  YT: 'Mayotte',
  ZA: 'Etelä-Afrikka',
  ZM: 'Sambia',
  ZW: 'Zimbabwe',
}
