import { get } from 'lodash'
import React, { ReactElement } from 'react'

import {
  interpolate,
  localeLanguage,
  LocaleSettings,
  MeasurLocale,
  uiTexts,
} from './i18n'

const Translate = ({
  locale,
  children,
}: {
  locale: MeasurLocale
  children(translation: Omit<LocaleSettings, 'locale'>): ReactElement
}) =>
  children({
    collator: Intl.Collator(localeLanguage(locale), {
      numeric: true,
      sensitivity: 'base',
    }),
    interpolate: (key, values) => {
      const fixedTranslation = get(uiTexts[locale], key.split('.')) as string
      if (typeof fixedTranslation === 'string') {
        return interpolate(fixedTranslation, values).map((value, i) => (
          <React.Fragment key={i}>{value}</React.Fragment>
        ))
      }

      throw new Error(`Missing translation key: ${key}`)
    },
    t: (key, fallback) => {
      const fixedTranslation = get(uiTexts[locale], key.split('.')) as string
      if (typeof fixedTranslation === 'string') {
        return fixedTranslation
      }
      if (typeof fallback !== 'undefined') {
        return fallback
      }
      throw new Error(`Missing translation key: ${key}`)
    },
  })

export default Translate
