import { IGatsbyImageData } from 'gatsby-plugin-image'
import { RenderRichTextData } from 'gatsby-source-contentful/rich-text'

import { APIEmployee } from '../../backend'
import { MeasurLocale } from '../locale/i18n'
import { ISlugLink } from '../locale/pathing'

import { IndustryOrMaterial, TypeOfTesting } from './catalog'
import {
  RichTextAssetReferences,
  RichTextEntryLinkReference,
  RichTextEntryReference,
} from './richText'

export interface SiteMetadata {
  title: string
  description: string
  author: string
  image: string
  siteUrl: string
}

export interface ContentfulNode {
  contentful_id: string
  node_locale: MeasurLocale
}

export interface ContentfulMember {
  id: string
  node_locale: MeasurLocale
  avatar: {
    gatsbyImageData: IGatsbyImageData
  } | null
  avatar80: {
    gatsbyImageData: IGatsbyImageData
  } | null
  avatar20: {
    gatsbyImageData: IGatsbyImageData
  } | null
  portrait: {
    gatsbyImageData: IGatsbyImageData
  } | null
  name: string
  position: string[] | null
  title: string | null
  email: string | null
  linkedIn: string | null
  company: string
  teams: string[] | null
}

export type TeamMember = Omit<ContentfulMember, 'name' | 'id' | 'node_locale'> &
  Pick<APIEmployee, 'name' | 'customerFacingPhone' | 'isActive'> & {
    id: string
    profileUrl?: string
  }

export interface GatsbyImage {
  contentful_id: string
  description?: string
  gatsbyImageData?: IGatsbyImageData
  title?: string
  url: string
}

export type Image = Omit<GatsbyImage, 'gatsbyImageData'>

export interface UniqueSellingPoint {
  title: string
  description: {
    description: string
  }
  image?: GatsbyImage
}

export interface ContentfulHeading {
  heading: string | null
  tableOfContentsHeading: string | null
  level: 'h2' | 'h3' | null
}

export type LandingPageType = 'solutions' | 'campaign'

export interface IFaqItem extends ContentfulNode {
  id: string
  question: string
  answer: RenderRichTextData<
    RichTextAssetReferences | RichTextEntryLinkReference
  >
}

export interface IContentfulMethod extends ContentfulNode {
  acronym: string
  contactFormInstructions: RenderRichTextData<never>
  description: RenderRichTextData<
    | RichTextAssetReferences
    | RichTextEntryLinkReference
    | RichTextEntryReference
  >
  deviceTypeSlug: string | null
  queryFilter: string | null
  faqItems?: IFaqItem[]
  heroImage?: {
    title: string
    url: string
  }
  heroImageFull?: {
    gatsbyImageData: IGatsbyImageData
  } | null
  heroImageThumbnail?: {
    gatsbyImageData: IGatsbyImageData
  } | null
  id: string
  idealUses: string[]
  idealUsesHeading?: string
  leadText: RenderRichTextData<
    RichTextAssetReferences | RichTextEntryLinkReference
  >
  metaTitle: string | null
  metaDescription: {
    metaDescription: string
  } | null
  methodNameLong: string
  methodNameShort?: string
  productListHeading: string
  sampleMatrices: string[]
  slug: string
  testimonial?: {
    customerName: string
    customerTitle: string
    customerCompany: string
    testimonial: {
      testimonial: string
    }
  }
}

export interface IContentfulLargeLeadForm extends ContentfulNode {
  title: string
  body: RenderRichTextData<RichTextAssetReferences | RichTextEntryLinkReference>
  image?: GatsbyImage
  actionTitle: string
  leadTag: string
  internal: {
    type: 'ContentfulLargeLeadForm'
  }
}

export interface IContentfulTable extends ContentfulNode {
  body: RenderRichTextData<RichTextAssetReferences | RichTextEntryLinkReference>
  internal: {
    type: 'ContentfulTable'
  }
  footnotes?: RenderRichTextData<
    RichTextAssetReferences | RichTextEntryLinkReference
  >
}

export interface IContentfulSmallLeadForm extends ContentfulNode {
  title: string
  actionTitle: string
  leadTag: string
  internal: {
    type: 'ContentfulSmallLeadForm'
  }
}

export interface IContentfulBlogPostSection extends ContentfulNode {
  content?: RenderRichTextData<
    | RichTextAssetReferences
    | RichTextEntryLinkReference
    | RichTextEntryReference
  >
  internal: {
    type: 'ContentfulBlogPostSection'
  }
}

export type BlogPostSection =
  | IContentfulBlogPostSection
  | IContentfulLargeLeadForm
  | IContentfulTable
  | IContentfulSmallLeadForm

export interface IContentfulBlogPost extends ContentfulNode {
  contactFormInstructions?: RenderRichTextData<never>
  customerCaseStudyLogo?: GatsbyImage
  footnotes?: RenderRichTextData<
    RichTextAssetReferences | RichTextEntryLinkReference
  >
  metaTitle: string | null
  metaDescription: {
    metaDescription: string
  } | null
  title: string
  slug: string
  description?: {
    description: string
  }
  author?: ContentfulMember
  showTableOfContents: boolean | null
  createdAt: string
  updateDate: string
  heroImage?: {
    url: string
  }
  heroImageFull: {
    gatsbyImageData: IGatsbyImageData
  } | null
  heroImageCardThumbnail: {
    gatsbyImageData: IGatsbyImageData
  } | null
  heroImageThumbnail: {
    gatsbyImageData: IGatsbyImageData
  } | null
  sidebarTitle?: string
  sidebarDescription?: string
  sidebarLink?: string
  sidebarLinkText?: string
  sections?: BlogPostSection[]
  whitelistedLocales: MeasurLocale[] | null
}

export interface IContentfulCategoryLanding extends ContentfulNode {
  metaTitle: string | null
  metaDescription: {
    metaDescription: string
  } | null
  intro: {
    intro: string
  }
  key: IndustryOrMaterial | TypeOfTesting | 'default'
  title: string
}

export interface IContentfulLandingSection extends ContentfulNode {
  bodyText: RenderRichTextData<
    RichTextAssetReferences | RichTextEntryLinkReference
  > | null
  expert?: ContentfulMember | null
  expertHeading?: string
  expertIntro?: string
  heading: string | null
  image?: GatsbyImage
  productIds?: string[]
  showCtaButton: boolean
  internal: {
    type: 'ContentfulLandingSection'
  }
}

export interface IContentfulLanding extends ContentfulNode {
  pageType?: LandingPageType
  contactFormHeading: string
  contactFormInstructions?: RenderRichTextData<never>
  heroCtaLink?: string
  heroCtaText?: string
  heroImage?: {
    url: string
    title: string
  }
  heroImageFull?: {
    gatsbyImageData: IGatsbyImageData
  } | null
  heroImageThumbnail: {
    gatsbyImageData: IGatsbyImageData
  } | null
  heroLeadText: {
    heroLeadText: string
  } | null
  heroTitle: string
  metaDescription: {
    metaDescription: string
  }
  metaTitle: string
  sections?: IContentfulLandingSection[]
  slug: string
  testimonial?: {
    customerName: string
    customerTitle: string
    customerCompany: string
    testimonial: {
      testimonial: string
    }
  }
}

export interface IContentfulTeamMember extends ContentfulNode {
  teamMember: ContentfulMember
  introduction: RenderRichTextData<
    RichTextAssetReferences | RichTextEntryLinkReference
  > | null
  leadText: RenderRichTextData<never>
  slug: string
  metaTitle: string
  metaDescription: {
    metaDescription: string
  }
}

export type TeamMemberPageLink = {
  slug: string
  teamMember: {
    email: string
  }
}

export enum ProductFilterType {
  INDUSTRY_OR_MATERIAL = 'industryOrMaterial',
  TYPE_OF_TESTING = 'typeOfTesting',
  DEVICE_TYPE = 'deviceType',
  TEXT = 'text',
  STANDARD = 'standard',
}

export enum ProjectFilterType {
  ORGANIZATION = 'organization',
  OWNER = 'owner',
  QUERY = 'query',
}

export enum FormFeedbackState {
  NONE = 'none',
  SUCCESS = 'success',
  LOADING = 'loading',
  ERROR = 'error',
}

export interface CustomerTestimonial {
  contentful_id: string
  node_locale: string
  testimonial: { testimonial: string }
  customerName?: string
  customerTitle?: string
  customerCompany?: string
}

export interface RoutingProduct {
  productData: {
    id: string
    slug: string
    webshopEnabled: boolean
  }
  locale: MeasurLocale
  localisedSlugs: ISlugLink[]
}

export interface RoutingContentfulRef {
  whitelistedLocales: MeasurLocale[] | null
  contentful_id: string
  node_locale: MeasurLocale
  slug: string
}

export interface RoutingContenfulLanding extends RoutingContentfulRef {
  pageType: LandingPageType
}

export type CollectionPageName = 'Methods page' | 'Solutions page'

export interface RoutingContenfulCollectionPage extends RoutingContentfulRef {
  collectionPageName: CollectionPageName
}
