import { AppState, Auth0Provider } from '@auth0/auth0-react'
import {
  navigate,
  WrapPageElementBrowserArgs,
  WrapRootElementBrowserArgs,
} from 'gatsby'
import React from 'react'

import { LocaleContext, MeasurLocale } from './src/locale/i18n'
import Translate from './src/locale/Translate'
import publicConfig from './src/logic/public-config'

import './src/assets/styles/_reset.scss'
import './src/assets/styles/_btn.scss'
import './src/assets/styles/_font.scss'
import './src/assets/styles/_globals.scss'
import './src/assets/styles/_heading.scss'
import './src/assets/styles/_inputField.scss'
import './src/assets/styles/_mixins.scss'
import './src/assets/styles/_richText.scss'
import './src/assets/styles/_tag.scss'
import './src/assets/styles/_skeleton.scss'

const onRedirectCallback = (appState?: AppState) => {
  void navigate(appState?.returnTo || '/', { replace: true })
}

export const wrapRootElement = ({ element }: WrapRootElementBrowserArgs) => {
  return (
    <Auth0Provider
      authorizeTimeoutInSeconds={publicConfig.oidc.authorizeTimeoutInSeconds}
      domain={String(publicConfig.oidc.domain)}
      clientId={String(publicConfig.oidc.clientId)}
      authorizationParams={{
        audience: publicConfig.oidc.audience,
        redirect_uri: `${window.location.origin}/auth/`,
        scope: 'openid profile email',
      }}
      issuer={publicConfig.oidc.issuer}
      onRedirectCallback={onRedirectCallback}
    >
      {element}
    </Auth0Provider>
  )
}

export const wrapPageElement = ({
  element,
  props: {
    pageContext: { locale },
  },
}: WrapPageElementBrowserArgs<
  Record<string, unknown>,
  { locale: MeasurLocale }
>) => {
  return (
    <>
      <Translate locale={locale}>
        {({ t, interpolate, collator }) => (
          <LocaleContext.Provider value={{ collator, interpolate, locale, t }}>
            {element}
          </LocaleContext.Provider>
        )}
      </Translate>
      <div id="portal" />
    </>
  )
}
